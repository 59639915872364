import React, { Fragment } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import {
  POST_PREVIEW_TEXT_LENGTH,
  FULL_PREVIEW_POSTS_COUNT,
  BLOG_ROLL_INDEX_LAST_SHORT_PREVIEW_POST,
} from "../utils/constants"
import { getTextFromSlices } from "../utils/helpers"
import { getPath } from "../utils/gatsby-node-helpers"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

const BlogRoll = ({ posts, locale, tags, categories, tagsUids }) => {
  return (
    <Fragment>
      <div className="row">
        {posts &&
          posts.slice(0, FULL_PREVIEW_POSTS_COUNT).map((post, i) => {
            return (
              <div
                key={i}
                className="col-md-6 col-lg-4 mb-4 d-flex flex-column"
              >
                <Link
                  to={getPath({
                    docType: "blog_post",
                    lang: post.lang,
                    uid: post.uid,
                  })}
                >
                  {post.data.cover.url && (
                    <img
                      // fluid={post.node.coverSharp.childImageSharp.fluid}
                      src={post.data.cover.url}
                      className="img-fluid"
                    />
                  )}
                </Link>
                <div className="py-3">
                  <div className="mb-2">
                    <Link
                      to={getPath({
                        docType: "category",
                        lang: post.lang,
                        uid: post?.data?.category?.uid,
                      })}
                      className="text-muted text-decoration-none"
                    >
                      <small className="border-bottom border-success">
                        {
                          categories.find(
                            c =>
                              c.uid === post.data.category.uid &&
                              c.lang === post.lang,
                          )?.data.category
                        }
                      </small>
                    </Link>
                  </div>
                  <h4 className="blog-teaser-title">
                    <Link
                      to={getPath({
                        docType: "blog_post",
                        lang: post.lang,
                        uid: post.uid,
                      })}
                      className="text-body"
                    >
                      {post.data.title.text}
                    </Link>
                  </h4>
                  <p className="m-0">
                    {post.data.body &&
                      getTextFromSlices(post.data.body).substring(
                        0,
                        POST_PREVIEW_TEXT_LENGTH,
                      )}
                    &nbsp;...
                  </p>
                </div>
                <div className="border-top mt-auto" />
              </div>
            )
          })}
        {tagsUids?.length && (
          <div className="col-md-6 col-lg-4 mb-4 d-flex flex-column">
            <div className="badge-wrapper">
              <h4 className="blog-teaser-title">Popular tags</h4>
              {tagsUids.map((tag, i) => (
                <Link
                  key={i}
                  className="badge badge-blog mr-1"
                  to={getPath({
                    docType: "custom_tag",
                    lang: locale,
                    uid: tag,
                  })}
                >
                  {
                    tags.find(t => t.uid === tag && t.lang === locale)?.data
                      .title.text
                  }
                </Link>
              ))}
            </div>
            <div className="mt-auto border-top" />
          </div>
        )}
      </div>
      <div className="row">
        {posts?.length > FULL_PREVIEW_POSTS_COUNT &&
          posts
            .slice(
              FULL_PREVIEW_POSTS_COUNT,
              BLOG_ROLL_INDEX_LAST_SHORT_PREVIEW_POST,
            )
            .map((post, i) => (
              <div
                key={i}
                className="col-md-6 col-lg-4 mb-3 d-flex flex-column"
              >
                <Link
                  to={getPath({
                    docType: "category",
                    lang: post.lang,
                    uid: post?.data?.category?.uid,
                  })}
                  className="text-muted text-decoration-none"
                >
                  <small className="border-bottom border-success">
                    {/* {post.node.category.category} */}
                    {post.data.category.uid}
                  </small>
                </Link>
                <h4 className="blog-teaser-title">
                  <Link
                    to={getPath({
                      docType: "blog_post",
                      lang: post.lang,
                      uid: post.uid,
                    })}
                    className="text-body"
                  >
                    {post.data.title.text}
                  </Link>
                </h4>
                <div className="border-top mt-auto" />
              </div>
            ))}
      </div>
    </Fragment>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      query BlogRollCategories {
        allPrismicCategory {
          nodes {
            data {
              category
            }
            uid
            lang
          }
        }
      }
    `}
    render={data => (
      <BlogRoll categories={data.allPrismicCategory.nodes} {...props} />
    )}
  />
)
